import React, { useState } from 'react';
import axios from 'axios';
import './App.css';  

function App() {
  const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  const [password, setPassword] = useState('');
  const [result, setResult] = useState('');
  const [fileDownloadLink, setFileDownloadLink] = useState(null);
  const [downloadFileName, setDownloadFileName] = useState(null);
  const [language, setLanguage] = useState('English');  // State for selected language
  const [copySuccess, setCopySuccess] = useState('');

//  let local_url = "http://localhost:5004";
  let local_url = "https://youmelifetranslate.info/api";

  const handleCopy = () => {
    navigator.clipboard.writeText(result).then(() => {
      setCopySuccess('Copied!');
    }, (err) => {
      setCopySuccess('Failed to copy!');
    });
  };


  // Handle language change
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    console.log(language);
  };

  // Handle Excel file upload
  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!file || !password) return alert('Please select a file and provide a password.');
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('password', password);
    formData.append('language', language);  // Add the selected language

    try {
      const response = await axios.post(`${local_url}/upload-excel`, formData, {
        responseType: 'blob',
      });
      
      // Extract filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
        : 'processed_file.xlsx';  // Default name in case extraction fails
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setFileDownloadLink(url);
      setDownloadFileName(filename);  // Set the filename for download
    } catch (error) {
      alert('Failed to upload file: ' + error.response.data.message);
    }
  };

  // Handle DOCX file upload
  const handleDocxUpload = async (e) => {
    e.preventDefault();
    if (!file || !password) return alert('Please select a .docx file and provide a password.');
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('password', password);
    formData.append('language', language);  // Add the selected language

    try {
      const response = await axios.post(`${local_url}/upload-docx`, formData, {
        responseType: 'blob',
      });

      // Extract filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
        : 'processed_file.docx';  // Default name if extraction fails

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setFileDownloadLink(url);
      setDownloadFileName(filename);  // Set the filename for download
    } catch (error) {
      alert('Failed to upload .docx file: ' + error.response.data.message);
    }
  };

  // Handle text submission
  const handleTextSubmit = async (e) => {
    e.preventDefault();
    if (!text || !password) return alert('Please provide text and password.');

    try {
      const response = await axios.post(`${local_url}/process-text`, { text, password, language });
      setResult(response.data.modifiedText);
    } catch (error) {
      alert('Failed to process text: ' + error.response.data.message);
    }
  };

  return (
    <div className="dashboard-container">
      <h1 className="title">File Processing Dashboard</h1>

      <section className="form-section">
        <h2>Upload Excel File</h2>
        <form onSubmit={handleFileUpload} className="form-container">
          <input type="file" onChange={(e) => setFile(e.target.files[0])} className="input-file" />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-password"
          />
          <select value={language} onChange={handleLanguageChange} className="select-language">
            <option value="English">English</option>
            <option value="Chinese">Chinese</option>
            <option value="Japanese">Japanese</option>
            <option value="Korean">Korean</option>
            <option value="French">French</option>
            <option value="German">German</option>
            <option value="Spanish">Spanish</option>
          </select>
          <button type="submit" className="submit-button">Upload Excel File</button>
        </form>
      </section>

      <section className="form-section">
        <h2>Upload DOCX File</h2>
        <form onSubmit={handleDocxUpload} className="form-container">
          <input type="file" onChange={(e) => setFile(e.target.files[0])} className="input-file" />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-password"
          />
          <select value={language} onChange={handleLanguageChange} className="select-language">
            <option value="English">English</option>
            <option value="Chinese">Chinese</option>
            <option value="Japanese">Japanese</option>
            <option value="Korean">Korean</option>
            <option value="French">French</option>
            <option value="German">German</option>
            <option value="Spanish">Spanish</option>
          </select>
          <button type="submit" className="submit-button">Upload DOCX File</button>
        </form>
      </section>

      {fileDownloadLink && (
        <div className="download-section">
          <a href={fileDownloadLink} download={downloadFileName} className="download-button">
            Download
          </a>
        </div>
      )}

      <section className="form-section">
        <h2>Process Text</h2>
        <form onSubmit={handleTextSubmit} className="form-container">
          <input
            type="text"
            placeholder="Enter text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="input-text"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-password"
          />
          <select value={language} onChange={handleLanguageChange} className="select-language">
            <option value="English">English</option>
            <option value="Chinese">Chinese</option>
            <option value="Japanese">Japanese</option>
            <option value="Korean">Korean</option>
            <option value="French">French</option>
            <option value="German">German</option>
            <option value="Spanish">Spanish</option>
          </select>
          <button type="submit" className="submit-button">Process Text</button>
        </form>

        {result && (
          <div className="result-container">
            <p className="result-text">Processed Text: {result}</p>
            <button onClick={handleCopy} className="copy-button">Copy</button>
            {copySuccess && <span className="copy-status">{copySuccess}</span>}
          </div>
        )}
      </section>
    </div>
  );
}

export default App;
